import React, { useMemo, useState } from "react";
import { Box, Card, Link, Tooltip, Typography } from "@mui/material";
import { UnderwriterProfile } from "james/ledger/AssetParticipants";
import { InfoPopUp } from "./InfoPopUp";
import { Info as InfoIcon } from "@mui/icons-material";

interface UnderwritersSectionProps {
  underwriters: UnderwriterProfile[];
}

export const UnderwritersSection = ({
  underwriters,
}: UnderwritersSectionProps) => {
  return (
    <>
      {underwriters.length > 0 && (
        <Box
          sx={{
            mt: 4,
            mx: { sm: 5, xs: 3 },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h5"
              sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
            >
              Underwriters
            </Typography>
            <Tooltip
              title="The Underwriter commits to ensuring that the required minimum bond issuance is taken up, (i.e. they agree upfront to subscribe for any shortfall in the target raise)."
              arrow
              placement="top"
            >
              <InfoIcon
                sx={(theme) => ({
                  ml: 1,
                  height: { sm: 16, xs: 20 },
                  width: { sm: 16, xs: 20 },
                  color: theme.palette.text.disabled,
                })}
              />
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              mt: 2,
            }}
          >
            {underwriters.map((u) => (
              <Box>
                <UnderwriterLineItem underwriter={u} />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

interface UnderwriterLineItemProps {
  underwriter: UnderwriterProfile;
}

export const UnderwriterLineItem = ({
  underwriter,
}: UnderwriterLineItemProps) => {
  const [showAssetViewMoreDialog, setShowViewMoreDialog] = useState(false);
  const handleClickOpen = () => {
    setShowViewMoreDialog(true);
  };

  const website = useMemo((): string => {
    try {
      return new URL(underwriter.webLink).hostname.replace("www.", "");
    } catch {
      return underwriter.webLink.replace("www.", "");
    }
  }, [underwriter]);

  return (
    <>
      <Card
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.custom.midnight,
          px: 3,
          py: 2,
          width: "100%",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            width: "100%",
            alignItems: { sm: "center" },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              mr: "auto",
            })}
          >
            {underwriter.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: { sm: "center", xs: "flex-start" },
              ml: { sm: "auto", xs: 0 },
              mt: { sm: 0, xs: 1 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                mr: "auto",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  color: theme.palette.custom.lavenderLight,
                })}
              >
                Commitment of up to
              </Typography>
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  ml: 0.5,
                  color: theme.palette.custom.lavenderLight,
                  fontWeight: "bold",
                })}
              >
                {underwriter.percentageUnderwritten}%
              </Typography>
            </Box>
            <Link
              onClick={handleClickOpen}
              sx={(theme) => ({ color: theme.palette.secondary.main, ml: 3 })}
              underline="hover"
            >
              View
            </Link>
          </Box>
        </Box>
      </Card>
      <InfoPopUp
        dialogProps={{ open: showAssetViewMoreDialog }}
        closeDialog={() => setShowViewMoreDialog(false)}
        title="Underwriter info"
        heading={underwriter.name}
        contactNumber={underwriter.contactNumber}
        email={underwriter.email}
        website={website}
        webLink={underwriter.webLink}
        address={underwriter.address.toString()}
        twitterLink={underwriter.twitterLink}
        facebookLink={underwriter.facebookLink}
        instagramLink={underwriter.instagramLink}
        youtubeLink={underwriter.youtubeLink}
        linkedInLink={underwriter.linkedInLink}
      />
    </>
  );
};
