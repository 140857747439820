import React, { useEffect } from "react";
import { Client, ClientKYCStatus, ClientStatus } from "james/client";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import dayjs from "dayjs";
import { useClientDetailsViewStore } from "./store";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import { useCompanyClientKYCStatusUpdater } from "./hooks/useCompanyClientStateUpdater";
import { useSaveClientDetails } from "./hooks/useSaveClientDetails";
import { DateField, TextField } from "components/FormFields";
import { dateIsValid } from "utilities/date";
import config from "react-global-configuration";
import { Environment } from "const";

type ClientViewProps = {
  Client: Client;
  readOnly?: boolean;
};

export function ClientView(props: ClientViewProps) {
  const store = useClientDetailsViewStore();
  useInitialDataFetch(props.Client);
  const { saveClientDetails } = useSaveClientDetails();
  const {
    markClientKYCStatusVerified,
    markClientKYCStatusOutstanding,
    markClientKYCStatusVerificationInProgress,
  } = useCompanyClientKYCStatusUpdater();
  const theme = useTheme();

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        overflowY: "unset",
        height: "94%",
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      <Backdrop open={store.loading}>
        <CircularProgress size={100} />
      </Backdrop>
      {/*Client Details Section*/}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          paddingBottom: (theme) => theme.spacing(2),
          paddingTop: (theme) => theme.spacing(4),
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Backdrop open={store.loading}>
          <CircularProgress size={100} />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            {props.Client.name}
          </Typography>
          <HistoryIcon color={"disabled"} />
          <div>
            <Typography variant={"body2"} color="textSecondary">
              {`Last updated ${dayjs(store.lastUpdateDate).format(
                "YYYY-MM-DD, HH:mm A",
              )}`}
            </Typography>
            <Typography variant={"caption"} color="textSecondary">
              {store.lastUpdateBy}
            </Typography>
          </div>
        </Box>
        {!props.readOnly && (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => saveClientDetails(props.Client)}
              disabled={store.loading}
              variant={"contained"}
              color={"primary"}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          overflowY: "scroll",
        }}
        className={"meshScroll"}
      >
        {/*Client Information Section*/}
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(4),
            paddingBottom: (theme) => theme.spacing(4),
            display: "flex",
            column: "column",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            Client Information
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            paddingBottom: (theme) => theme.spacing(3),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
            >
              <Typography
                color={"textSecondary"}
                children={"Type"}
                variant={"caption"}
              />
              <Typography
                children={props.Client.clientTypes}
                variant={"body1"}
              />
            </Box>
            <Box>
              <DateField
                readOnly={props.readOnly}
                label="KYC Reset Date"
                sx={{
                  width: "100%",
                }}
                id="clientView-kycResetDate-formfield"
                value={store.kycResetDate}
                onChange={(newValue) => {
                  if (!(newValue && dateIsValid(newValue))) {
                    return;
                  }
                  store.setKYCResetDate(dayjs(newValue).format());
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    margin: "dense",
                    fullWidth: true,
                    placeholder: "MM/DD/YYYY",
                    InputLabelProps: { shrink: true },
                  },
                }}
              />
            </Box>

            <Box>
              <DateField
                readOnly={props.readOnly}
                label="KYC Verification Date"
                id="clientView-kycVerificationDate-formfield"
                sx={{
                  width: "100%",
                }}
                value={store.kycVerificationDate}
                onChange={(newValue) => {
                  if (!(newValue && dateIsValid(newValue))) {
                    return;
                  }
                  store.setKYCVerificationDate(dayjs(newValue).format());
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    margin: "dense",
                    fullWidth: true,
                    placeholder: "MM/DD/YYYY",
                    InputLabelProps: { shrink: true },
                  },
                }}
              />
            </Box>

            <TextField
              readOnly={props.readOnly}
              id="clientView-name-formfield"
              margin="dense"
              variant="outlined"
              label="Short Name"
              placeholder="e.g Registered Client Name"
              value={store.shortName}
              onChange={(e) => store.setShortName(e.target.value)}
              fullWidth
            />
            <TextField
              readOnly={props.readOnly}
              id="clientView-shortName-formfield"
              margin="dense"
              variant="outlined"
              label="Name"
              value={store.name}
              onChange={(e) => store.setName(e.target.value)}
              placeholder="e.g Registered Company Name"
              fullWidth
            />
            <DateField
              readOnly={props.readOnly}
              id="clientView-registrationDate-formfield"
              sx={{
                width: "100%",
              }}
              label="Registration Date"
              value={store.registrationDate}
              onChange={(newValue) => {
                if (!(newValue && dateIsValid(newValue))) {
                  return;
                }
                store.setRegistrationDate(dayjs(newValue).format());
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  margin: "dense",
                  fullWidth: true,
                  placeholder: "MM/DD/YYYY",
                  InputLabelProps: { shrink: true },
                },
              }}
            />
            <TextField
              readOnly={props.readOnly}
              style={{
                whiteSpace: "pre-wrap",
                color: theme.palette.text.primary,
                fontSize: 16,
                width: "100%",
              }}
              rows={7}
              label={"Description"}
              multiline
              value={store.description}
              onChange={(e) => store.setDescription(e.target.value)}
              placeholder="Type Something here"
            />
            <Box
              sx={{
                marginTop: (theme) => theme.spacing(1),
              }}
            >
              <Typography
                color={"textSecondary"}
                children={"PAAS Agreement Version"}
                variant={"caption"}
              />
              <Typography
                children={store.paasAgreementVersion}
                variant={"body1"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              readOnly={props.readOnly}
              id="clientView-website-formfield"
              margin="dense"
              variant="outlined"
              label="Website"
              placeholder={"e.g. www.hello.com"}
              value={store.website}
              onChange={(e) => store.setWebsite(e.target.value)}
              fullWidth
            />
            <TextField
              readOnly={props.readOnly}
              id="clientView-linkedIn-formfield"
              margin="dense"
              variant="outlined"
              label="LinkedIn"
              placeholder={"e.g. www.hello.com"}
              value={store.linkedIn}
              onChange={(e) => store.setLinkedin(e.target.value)}
              fullWidth
            />
            <TextField
              readOnly={props.readOnly}
              id="clientView-twitter-formfield"
              margin="dense"
              variant="outlined"
              label="Twitter"
              placeholder={"e.g. www.hello.com"}
              value={store.twitter}
              onChange={(e) => store.setTwitter(e.target.value)}
              fullWidth
            />
            <TextField
              readOnly={props.readOnly}
              id="clientView-otherSite-formfield"
              margin="dense"
              variant="outlined"
              label="Other Site"
              placeholder={"e.g. www.hello.com"}
              value={store.otherSite}
              onChange={(e) => store.setOtherSite(e.target.value)}
              fullWidth
            />
            <Typography
              sx={{
                marginTop: (theme) => theme.spacing(3),
                marginBottom: (theme) => theme.spacing(1),
              }}
              variant={"h6"}
              fontWeight={"bold"}
            >
              Status
            </Typography>
            <FormControl
              disabled
              sx={{
                paddingBottom: (theme) => theme.spacing(1),
                marginLeft: (theme) => theme.spacing(1),
              }}
            >
              <RadioGroup
                defaultValue={ClientStatus.ActiveStatus}
                value={store.selectedClientStatus}
                onChange={(e, v) =>
                  store.setSelectedClientStatus(v as ClientStatus)
                }
              >
                <FormControlLabel
                  value={ClientStatus.ActiveStatus}
                  label={ClientStatus.ActiveStatus}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={ClientStatus.InactiveStatus}
                  label={ClientStatus.InactiveStatus}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={ClientStatus.CompleteProfile}
                  label={ClientStatus.CompleteProfile}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            paddingBottom: (theme) => theme.spacing(3),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                paddingTop: (theme) => theme.spacing(4),
                paddingBottom: (theme) => theme.spacing(4),
                display: "flex",
                column: "column",
                width: "100%",
              }}
            >
              <Typography variant={"h4"} fontWeight={"bold"}>
                Verification Information
              </Typography>
            </Box>
            <Typography variant={"h6"} fontWeight={"bold"}>
              KYC Status
            </Typography>
            <FormControl
              disabled={props.readOnly}
              sx={{
                paddingBottom: (theme) => theme.spacing(1),
                marginLeft: (theme) => theme.spacing(1),
              }}
            >
              <RadioGroup
                defaultValue={ClientKYCStatus.Outstanding}
                onChange={(e, v) => {
                  if (
                    v === ClientKYCStatus.VerificationInProgressStatus &&
                    config.get("environment") !== Environment.Testing
                  ) {
                    return;
                  }
                  store.setSelectedKYCStatus(v as ClientKYCStatus);
                }}
                value={store.selectedKYCStatus}
              >
                <FormControlLabel
                  value={ClientKYCStatus.Outstanding}
                  control={<Radio />}
                  label={ClientKYCStatus.Outstanding}
                />
                <FormControlLabel
                  control={<Radio />}
                  value={ClientKYCStatus.VerificationInProgressStatus}
                  label={ClientKYCStatus.VerificationInProgressStatus}
                />
                <FormControlLabel
                  value={ClientKYCStatus.VerifiedStatus}
                  control={<Radio />}
                  label={ClientKYCStatus.VerifiedStatus}
                />
              </RadioGroup>
              {!props.readOnly && (
                <Button
                  sx={{ marginTop: (theme) => theme.spacing(2) }}
                  variant={"outlined"}
                  disabled={store.loading}
                  onClick={async () => {
                    if (
                      store.selectedKYCStatus === ClientKYCStatus.VerifiedStatus
                    ) {
                      await markClientKYCStatusVerified(props.Client);
                      return;
                    }

                    if (
                      store.selectedKYCStatus === ClientKYCStatus.Outstanding
                    ) {
                      await markClientKYCStatusOutstanding(props.Client);
                      return;
                    }

                    if (
                      store.selectedKYCStatus ===
                      ClientKYCStatus.VerificationInProgressStatus &&
                      config.get("environment") === Environment.Testing
                    ) {
                      await markClientKYCStatusVerificationInProgress(
                        props.Client,
                      );
                      return;
                    }
                  }}
                >
                  {`Mark as ${store.selectedKYCStatus}`}
                </Button>
              )}
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginTop: (theme) => theme.spacing(10),
            }}
          >
            <TextField
              readOnly={props.readOnly}
              style={{
                whiteSpace: "pre-wrap",
                color: theme.palette.text.primary,
                fontSize: 16,
                width: "100%",
              }}
              rows={7}
              label={"Status Reason"}
              multiline
              value={store.kycStatusReason}
              onChange={(e) => store.setKYCStatusReason(e.target.value)}
              placeholder="Type Something here"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
