import React, { useMemo } from "react";
import { alpha, Box, useTheme } from "@mui/material";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";

interface ConnectionIconProps {
  connectionStatus: ServerConnectionStatus;
}

export const ConnectionIcon = ({ connectionStatus }: ConnectionIconProps) => {
  const theme = useTheme();
  const color = useMemo(() => {
    let color = "#FFFF00";
    switch (connectionStatus) {
      case ServerConnectionStatus.Connected:
        color = theme.palette.success.main;
        break;
      case ServerConnectionStatus.Disconnected:
        color = theme.palette.error.main;
        break;
    }
    return color;
  }, [connectionStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 18,
        height: 18,
        borderRadius: "50%",
        backgroundColor: alpha(color, 0.38),
        transition: "background-color 0.2s ease-in-out",
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          width: 9,
          height: 9,
          backgroundColor: color,
          transition: "background-color 0.2s ease-in-out",
        }}
      />
    </Box>
  );
};
