import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useLimitOrderController } from "../../../hooks/useLimitOrderController";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";
import { limitOrderTypeToString } from "@mesh/common-js/dist/market/limitOrderType";

interface CancelConfirmationDialog {
  open: boolean;
  closeDialog: () => void;
  model: Model;
}

export const CancelConfirmationDialog = ({
  open,
  closeDialog,
  model,
}: CancelConfirmationDialog) => {
  const { cancelLimitOrder } = useLimitOrderController();
  const [loading, setLoading] = React.useState(false);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: (theme) => ({
          width: { sm: 552, xs: "100%" },
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            bottom: 0,
            m: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }),
      }}
    >
      <DialogTitle>
        <Typography>
          Cancel {limitOrderTypeToString(model.getType())} Order
        </Typography>
        {loading ? (
          <CircularProgress size={16} />
        ) : (
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          color="textSecondary"
          sx={{
            mt: 3,
            mb: {
              xs: 8,
              sm: 0,
            },
          }}
        >
          Are you sure you want to cancel this order? You cannot undo this
          action.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 2,
            gap: 3,
          }}
        >
          <Button fullWidth={smDown} variant="outlined" onClick={closeDialog}>
            Close
          </Button>
          <Button
            fullWidth={smDown}
            variant="contained"
            color="primary"
            onClick={async () => {
              setLoading(true);
              await cancelLimitOrder(model);
              setLoading(false);
            }}
          >
            Proceed
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
