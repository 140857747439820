import React, { PropsWithChildren, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TradeCard } from "./components/TradeCard/TradeCard";
import { TradesList } from "./components/TradesList";
import {
  AllExchangeTabs,
  ExchangeTab,
  useExchangeDashboardStore,
} from "./store";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { ExchangeActions, ExchangeStore, useExchangeStore } from "../../store";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import { IconPair } from "../IconPair/IconPair";
import { motion } from "framer-motion";
import { OrderBookView } from "./components/OrderBook";
import { MyOffers } from "./components/MyOffers/MyOffers";
import { TabContext, TabPanel } from "@mui/lab";
import { MobileNavigator } from "components/BottomNavigation/MobileNavigator";
import { useAppTourContext } from "context/AppTour/AppTour";

interface ExchangeDashboardProps {
  exchangeStore: ExchangeStore & ExchangeActions;
}

export const ExchangeDashboard = ({
  exchangeStore,
}: ExchangeDashboardProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    store.clearStore();
  }, [exchangeStore.marketListingPair]);

  useInitialDataFetch();
  const store = useExchangeDashboardStore();
  const navigate = useNavigate();

  return (
    <EchangeWrapper>
      {!smDown && (
        <Box
          sx={(theme) => ({
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: {
              xs: theme.palette.custom.midnight,
            },
            height: { sm: 50 },
            borderRadius: {
              xs: 0,
              sm: "10px",
            },
            mt: {
              xs: 0,
              sm: 2,
            },
            py: { xs: 1, sm: 0 },
          })}
        >
          <IconButton sx={{ m: { xs: 0, sm: 2 } }} onClick={() => navigate(-1)}>
            <ArrowBackIosNewIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {exchangeStore.marketListingPair && (
              <Box sx={{ mr: { xs: 2, sm: 3 } }}>
                <IconPair
                  size={32}
                  firstToken={exchangeStore.marketListingPair.base}
                  secondToken={exchangeStore.marketListingPair.counter}
                />
              </Box>
            )}
            <Typography variant="h4" fontWeight={"bold"}>
              {exchangeStore.marketListingPair?.base.code}/
              {exchangeStore.marketListingPair?.counter.code}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row",
              },
              gap: 2,
              ml: "auto",
              mr: 2,
            }}
          >
            <Button onClick={() => navigate("")}>Back To Exchange</Button>
            <Button
              onClick={() =>
                navigate(
                  `/market/asset-overview?code=${exchangeStore.selectedMarketListing?.token.code}&issuer=${exchangeStore.selectedMarketListing?.token.issuer}&network=${exchangeStore.selectedMarketListing?.token.network}`,
                )
              }
            >
              View Asset
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* content */}
        {smDown ? <MobileContent /> : <DesktopContent />}
      </Box>
    </EchangeWrapper>
  );
};

const MobileContent = () => {
  const store = useExchangeDashboardStore();
  return (
    <>
      <TabContext value={store.selectedTab}>
        <TabPanel
          sx={{ p: 0, width: "100%", height: "100%" }}
          value={ExchangeTab.Trade}
        >
          <TradeCard
            {...{ ...store.tradeCardState, ...store.updateTradeCardState }}
          />
        </TabPanel>
        <TabPanel
          sx={{ p: 0, width: "100%", height: "100%" }}
          value={ExchangeTab.OrderBook}
        >
          <OrderBookView {...store.orderBookState} />
        </TabPanel>
        <TabPanel
          sx={{ p: 0, width: "100%", height: "100%" }}
          value={ExchangeTab.History}
        >
          <TradesList {...store.tradesListState} />
        </TabPanel>
        <TabPanel
          sx={{ p: 0, width: "100%", height: "100%" }}
          value={ExchangeTab.Orders}
        >
          <MyOffers
            {...{ ...store.openOrdersState, ...store.updateOpenOrdersState }}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

const DesktopContent = () => {
  const store = useExchangeDashboardStore();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "100%",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplate: {
            sm: `
              "a" auto
              "b" auto
              "c" auto / 1fr;
            `,
            md: `
              "a c" auto
              "b b" auto / 1fr 1fr;
            `,
            lg: `
              "a b c" 1fr / 320px 1fr 320px;
            `,
          },
          width: {
            xs: "100%",
            sm: "80%",
            lg: "100%",
          },
        }}
      >
        <motion.div
          initial={{ x: "-100%", opacity: 0 }}
          exit={{ x: "-100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 20,
          }}
          style={{ minWidth: 280, gridArea: "a" }}
        >
          <TradeCard
            {...{ ...store.tradeCardState, ...store.updateTradeCardState }}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 20,
          }}
          style={{ minWidth: 320, gridArea: "b" }}
        >
          <OrderBookView {...store.orderBookState} />
        </motion.div>
        <motion.div
          initial={{ x: "100%", opacity: 0 }}
          exit={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 20,
          }}
          style={{ minWidth: 280, gridArea: "c" }}
        >
          <TradesList {...store.tradesListState} />
        </motion.div>
      </Box>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20,
        }}
        style={{ width: "100%" }}
      >
        <MyOffers
          {...{ ...store.openOrdersState, ...store.updateOpenOrdersState }}
        />
      </motion.div>
    </Box>
  );
};

const EchangeWrapper: React.FC<PropsWithChildren> = (props) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { registerElement } = useAppTourContext();
  const navigate = useNavigate();
  const exchangeStore = useExchangeStore();
  const store = useExchangeDashboardStore();

  return smDown ? (
    <Dialog fullScreen open>
      <DialogTitle sx={{ p: 0 }}>
        <Box
          sx={(theme) => ({
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: {
              xs: theme.palette.custom.midnight,
            },
            height: { sm: 50 },
            borderRadius: {
              xs: 0,
              sm: "10px",
            },
            mt: {
              xs: 0,
              sm: 2,
            },
            py: { xs: 1, sm: 0 },
          })}
        >
          <IconButton sx={{ m: { xs: 0, sm: 2 } }} onClick={() => navigate(-1)}>
            <ArrowBackIosNewIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {exchangeStore.marketListingPair && (
              <Box sx={{ mr: { xs: 2, sm: 3 } }}>
                <IconPair
                  size={32}
                  firstToken={exchangeStore.marketListingPair.base}
                  secondToken={exchangeStore.marketListingPair.counter}
                />
              </Box>
            )}
            <Typography variant="h4" fontWeight={"bold"}>
              {exchangeStore.marketListingPair?.base.code}/
              {exchangeStore.marketListingPair?.counter.code}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogTitle sx={{ p: 0 }}>
        <Box
          sx={(theme) => ({
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme.palette.background.default,
          })}
        >
          <Tabs value={store.selectedTab}>
            {AllExchangeTabs.map((e) => {
              return (
                <Tab
                  value={e}
                  onClick={() => store.setSelectedTab(e)}
                  label={
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {e}
                    </Typography>
                  }
                />
              );
            })}
          </Tabs>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          height: "calc(100% - 179px)",
          backgroundColor: theme.palette.background.default,
          p: 0,
        })}
      >
        {props.children}
      </DialogContent>
      <DialogContent sx={{ p: 0 }}>
        {smDown && (
          <DialogContent
            ref={registerElement("marketplaceLayoutMobileNav")}
            sx={{
              p: 0,
              zIndex: 1300,
              minHeight: "75px",
            }}
          >
            <MobileNavigator />
          </DialogContent>
        )}
      </DialogContent>
    </Dialog>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 1280,
        width: "100%",
        px: 2,
        pb: 5,
      }}
    >
      {props.children}
    </Box>
  );
};
