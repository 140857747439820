import { AuditEntry } from "james/audit";
import { StockExchange } from "james/financial/StockExchange";
import {
  CompanyRepresentative,
  ConnectedCompany,
  ConnectedIndividual,
  LegalForm,
} from "james/legal";
import { IndustryClassification } from "james/legal/IndustryClassification";
import { Address } from "james/location";
import { CompanyPublicContactInfo } from "../CompanyPublicContactInfo";

export class Company {
  public id = "";

  public ownerID = "";

  public registeredName = "";

  public industryClassification: IndustryClassification =
    new IndustryClassification();

  public listedExchange: StockExchange = { code: "", name: "" };
  public listingReference = "";

  public taxReferenceNumber = "";

  public registrationNumber = "";

  public vatRegistrationNumber = "";

  public companyRepresentative: CompanyRepresentative =
    new CompanyRepresentative();

  public publicContactInfo: CompanyPublicContactInfo =
    new CompanyPublicContactInfo();

  public countryOfIncorporation = "ZA";
  public formOfIncorporation: LegalForm | "" | undefined = undefined;

  public registeredAddress: Address = new Address();

  public businessAddress: Address = new Address();

  public headOfficeAddress: Address = new Address();

  public connectedIndividuals: ConnectedIndividual[] = [];

  public connectedCompanies: ConnectedCompany[] = [];

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(company?: Company) {
    if (!company) {
      return;
    }
    this.id = company.id;
    this.ownerID = company.ownerID;
    this.registeredName = company.registeredName;
    this.industryClassification = new IndustryClassification(
      company.industryClassification,
    );

    this.listedExchange = { ...company.listedExchange };
    this.listingReference = company.listingReference;

    this.taxReferenceNumber = company.taxReferenceNumber;
    this.registrationNumber = company.registrationNumber;
    this.vatRegistrationNumber = company.vatRegistrationNumber;
    this.companyRepresentative = new CompanyRepresentative(
      company.companyRepresentative,
    );
    this.publicContactInfo = new CompanyPublicContactInfo(
      company.publicContactInfo,
    );
    this.countryOfIncorporation = company.countryOfIncorporation;
    this.formOfIncorporation = company.formOfIncorporation;

    this.registeredAddress = new Address(company.registeredAddress);
    this.businessAddress = new Address(company.businessAddress);
    this.headOfficeAddress = new Address(company.headOfficeAddress);

    if (company.connectedIndividuals) {
      this.connectedIndividuals = company.connectedIndividuals.map(
        (c) => new ConnectedIndividual(c),
      );
    } else {
      this.connectedIndividuals = [];
    }
    if (company.connectedCompanies) {
      this.connectedCompanies = company.connectedCompanies.map(
        (c) => new ConnectedCompany(c),
      );
    } else {
      this.connectedCompanies = [];
    }
    this.auditEntry = new AuditEntry(company.auditEntry);

    this.industryClassification = new IndustryClassification(
      company.industryClassification,
    );
  }
}
